import { Table } from "antd";
import React from "react";

function ProductTable() {
  const data = [
    {
      key: "1",
      product: "Classic Cars",
      totalRevenue: 3919615.66,
      totalUnitsSold: 33992,
      averagePrice: 87.34,
    },
    {
      key: "2",
      product: "Vintage Cars",
      totalRevenue: 1903150.84,
      totalUnitsSold: 21069,
      averagePrice: 78.15,
    },
    {
      key: "3",
      product: "Motorcycles",
      totalRevenue: 1166388.34,
      totalUnitsSold: 11663,
      averagePrice: 83.0,
    },
    {
      key: "4",
      product: "Trucks and Buses",
      totalRevenue: 1127789.84,
      totalUnitsSold: 10777,
      averagePrice: 87.53,
    },
    {
      key: "5",
      product: "Planes",
      totalRevenue: 975003.57,
      totalUnitsSold: 10727,
      averagePrice: 81.74,
    },
    {
      key: "6",
      product: "Ships",
      totalRevenue: 714437.13,
      totalUnitsSold: 8127,
      averagePrice: 83.86,
    },
    {
      key: "7",
      product: "Trains",
      totalRevenue: 226243.47,
      totalUnitsSold: 2712,
      averagePrice: 75.65,
    },
  ];

  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Total Revenue",
      dataIndex: "totalRevenue",
      key: "totalRevenue",
      render: (text) => `$${text.toFixed(2)}`, // تنسيق قيمة الإيرادات
    },
    {
      title: "Total Units Sold",
      dataIndex: "totalUnitsSold",
      key: "totalUnitsSold",
    },
    {
      title: "Average Price",
      dataIndex: "averagePrice",
      key: "averagePrice",
      render: (text) => `$${text.toFixed(2)}`, // تنسيق متوسط السعر
    },
  ];

  return (
    <Table bordered pagination={false} dataSource={data} columns={columns} />
  );
}

export default ProductTable;
