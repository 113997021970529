import React from "react";
import { Table } from "antd";

const monthlyData = [
  {
    key: "0",
    month: "0",
    totalRevenue: 785874.44,
    totalUnitsSold: 7997,
    averagePrice: 83.46,
  },
  {
    key: "1",
    month: "1",
    totalRevenue: 810441.9,
    totalUnitsSold: 7903,
    averagePrice: 84.39,
  },
  {
    key: "2",
    month: "2",
    totalRevenue: 754501.39,
    totalUnitsSold: 7585,
    averagePrice: 82.23,
  },
  {
    key: "3",
    month: "3",
    totalRevenue: 669390.96,
    totalUnitsSold: 6704,
    averagePrice: 82.94,
  },
  {
    key: "4",
    month: "4",
    totalRevenue: 923972.56,
    totalUnitsSold: 8992,
    averagePrice: 83.88,
  },
  {
    key: "5",
    month: "5",
    totalRevenue: 454756.78,
    totalUnitsSold: 4620,
    averagePrice: 83.0,
  },
  {
    key: "6",
    month: "6",
    totalRevenue: 514875.97,
    totalUnitsSold: 4899,
    averagePrice: 85.86,
  },
  {
    key: "7",
    month: "7",
    totalRevenue: 659310.57,
    totalUnitsSold: 6538,
    averagePrice: 83.63,
  },
  {
    key: "8",
    month: "8",
    totalRevenue: 584724.27,
    totalUnitsSold: 5681,
    averagePrice: 83.5,
  },
  {
    key: "9",
    month: "9",
    totalRevenue: 1121215.22,
    totalUnitsSold: 10998,
    averagePrice: 83.95,
  },
  {
    key: "10",
    month: "10",
    totalRevenue: 2118885.67,
    totalUnitsSold: 20857,
    averagePrice: 83.74,
  },
  {
    key: "11",
    month: "11",
    totalRevenue: 634679.12,
    totalUnitsSold: 6293,
    averagePrice: 83.25,
  },
];

const columns = [
  {
    title: "Month",
    dataIndex: "month",
    key: "month",
  },
  {
    title: "Total Revenue",
    dataIndex: "totalRevenue",
    key: "totalRevenue",
    render: (text) => `$${text.toFixed(2)}`, // تنسيق قيمة الإيرادات
  },
  {
    title: "Total Units Sold",
    dataIndex: "totalUnitsSold",
    key: "totalUnitsSold",
  },
  {
    title: "Average Price",
    dataIndex: "averagePrice",
    key: "averagePrice",
    render: (text) => `$${text.toFixed(2)}`, // تنسيق متوسط السعر
  },
];

const MonthlyRevenueTable = () => {
  return (
    <Table
      bordered
      pagination={false}
      dataSource={monthlyData}
      columns={columns}
    />
  );
};

export default MonthlyRevenueTable;
